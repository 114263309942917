// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}platformNotice/listPlatformNotice`
// 获取公告类型列表
const getNoticeTypeURL = `${API_CONFIG.butlerBaseURL}platformNotice/listPlatFormNoticeType`
// 关闭操作
const closeHandleURL = `${API_CONFIG.butlerBaseURL}platformNotice/closePlatformNotice`

// 新增
const createURL = `${API_CONFIG.butlerBaseURL}platformNotice/savePlatformNoticeInfo`
// 编辑
const editURL = `${API_CONFIG.butlerBaseURL}platformNotice/updatePlatformNoticeInfo`
// 查询详情
const queryURL = `${API_CONFIG.butlerBaseURL}platformNotice/getPlatformNoticeInfo`

export {
  getListURL,
  getNoticeTypeURL,
  closeHandleURL,
  createURL,
  editURL,
  queryURL
}
