var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "notice-pop-view",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.hide.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "content-pop",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("div", { staticClass: "header-pop" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.detail.title)),
            ]),
            _c("span", [
              _vm._v(
                "\n        " + _vm._s(_vm.detail.noticeTimeStart) + "\n        "
              ),
              _c("i", {
                staticClass: "el-icon-close btn-icon",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.hide.apply(null, arguments)
                  },
                },
              }),
            ]),
          ]),
          _c("div", {
            staticClass: "content-block",
            domProps: { innerHTML: _vm._s(_vm.detail.content) },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }