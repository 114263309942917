<template>
  <div class="notice-pop-view" @click.stop="hide">
    <div class="content-pop" @click.stop>
      <div class="header-pop">
        <span class="title">{{detail.title}}</span>
        <span>
          {{detail.noticeTimeStart}}
          <i class="el-icon-close btn-icon" @click.stop="hide"></i>
        </span>

      </div>
      <div class="content-block" v-html="detail.content"></div>
    </div>
  </div>
</template>
<script>
import { queryURL } from './api'
import moment from 'moment'
export default {
  props: {
    id: [String, Number],
    detailVisible: Boolean
  },
  data () {
    return {
      detail: {}
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    hide () {
      this.$emit('update:detailVisible', false)
    },
    getDetail () {
      this.$axios.get(`${queryURL}?id=${this.id}`).then(res => {
        if (res.status === 100) {
          if (res.data && res.data.noticeTimeStart && res.data.noticeTimeStart.length) {
            res.data.noticeTimeStart = moment(res.data.noticeTimeStart).format('YYYY-MM-DD HH:mm')
          }
          this.detail = res.data
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.notice-pop-view {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0,0,0, 0.5);
  z-index: 999;
  .content-pop {
    width: 800px;
    max-height: 80vh;
    margin: 10vh auto;
    background: #fff;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    .header-pop {
      background: #e3f0ff;
      min-height: 44px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 20px;
      }
      .btn-icon {
        cursor: pointer;
      }
    }
    .content-block {
      padding: 20px;
      overflow-y: auto;
      text-align: left;
    }
  }
}
::v-deep {
  .col2-item .text {
    text-align: left;
  }
}
</style>
